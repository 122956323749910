import React, { useContext, useState, useEffect, LegacyRef } from "react";
import { mdiArrowExpand, mdiCheckCircleOutline, mdiDotsHorizontal, mdiStar, mdiStarOff, mdiVideo } from "@mdi/js";
import Icon from "@mdi/react";
import { IMedia } from "../../interfaces/story";
import { Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ProfileVisualsFiltersBar, { EFilterBarContainer, profileVisualSortOptions } from "./profile-visuals-filter-bar.component";
import { Context } from "../../pages/home.page";
import instance from "../../api/api";
import VisualDetailsModal from "../story/story-visual-details.component";
import ImageFullscreenViewer from "../_helper/image-fullscreen-viewer";
import { getDuration } from "../story/story-visuals.component";
import IFrameDisplay from "../_helper/iframe-display.component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

interface IProps {
    visuals:IMedia[]
    loadMore: Function
    hasMore:boolean
    loading: boolean
    setFav: Function
    fav: boolean
    totalCount: number
    setSortOption: Function
    loadMoreRef: LegacyRef<HTMLDivElement> | undefined
}

export default function ProfileVisualImages(props:IProps) {

    const [ selected, setSelected ] = useState<IMedia[]>([]);
    const [ selectScreen, setSelectScreen ] = useState<boolean>(false);
    const [ showSelectedDetails, setShowSelectedDetails ] = useState<boolean>(false);
    const [ sort, setSort ] = useState<profileVisualSortOptions>(profileVisualSortOptions.UPLOAD_DATE);

    const [ showVisualDetails, setShowVisualsDetails ] = useState<boolean>(false);
    const [ visualDetails, setVisualDetails ] = useState<IMedia>();
    const [ fullScreen, setFullScreen ] = useState<boolean>(false);


    const history = useHistory();
    const userData = useContext(Context).user;
    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();

    useEffect(() => {

        switch (sort){
            case profileVisualSortOptions.UPLOAD_DATE:
                props.setSortOption({field:'createdAt', type:'desc'})
                break
            case profileVisualSortOptions.OLDEST:
                props.setSortOption({field:'relatedStory.date.startDate.date', type:'asc'})
                break
            case profileVisualSortOptions.NEWEST:
                props.setSortOption({field:'relatedStory.date.startDate.date', type:'desc'})
                break
            case profileVisualSortOptions.VIDEOS_FIRST:
                props.setSortOption({field:'fileType', type:'desc'})
                break
            case profileVisualSortOptions.IMAGES_FIRST:
                props.setSortOption({field:'fileType', type:'asc'})
                break
        }

    }, [ sort ])

    function selectAll() {
        setSelected(props.visuals);

    }

    function imageclick(media:IMedia) {
        if(selectScreen) {
            if(selected.map(x => x._id).includes(media._id)) {
                setSelected(selected.filter(x => media._id !== x._id))
            }
            else {
                setSelected(prev => [...prev, media])

            }
        }
        else {
            setVisualDetails(media);
            setShowVisualsDetails(true);
        }
    }

    function addToFavorites() {
        const ids = selected.map(async (data) => {
            if(!userData.favorites.media.includes(data._id)) {
                await instance.put(`/user/${userData._id}`, { push: { "favorites.media": data._id }});
                return data._id
            }
        });

        Promise.all(ids).then(results => {
            updateLoggedInUserData({...userData, favorites:{...userData.favorites, media:[...userData.favorites.media, ...(results as string[])]}})
        });

         setShowSelectedDetails(false);
    }

    function removeFromFavorites() {
        const ids = selected.map(async (data) => {
            if(userData.favorites.media.includes(data._id)) {
                await instance.put(`/user/${userData._id}`, { pull: { "favorites.media": data._id }});
                return data._id
            }
        });

        Promise.all(ids).then(results => {
            updateLoggedInUserData({...userData, favorites:{...userData.favorites, media:userData.favorites.media.filter(x => !(results as string[]).includes(x))}})
        });

         setShowSelectedDetails(false);
    }    

    function previousVisual() {
        const currentIndex = props.visuals.findIndex((data) => data._id === visualDetails?._id);
        if(props.visuals[currentIndex-1]) {
            setVisualDetails(props.visuals[currentIndex-1])
        }
    }

    function nextVisual() {
        const currentIndex = props.visuals.findIndex((data) => data._id === visualDetails?._id);
        if(props.visuals[currentIndex+1]) {
            setVisualDetails(props.visuals[currentIndex+1])
        }
    }

    return(
        <div className="profile-visual-images">
            <ProfileVisualsFiltersBar container={EFilterBarContainer.MEDIA} setSort={setSort} sort={sort} setFavourite={props.setFav} favourite={props.fav}/>

            <div className="info-row">
                    <div className="left">
                        {selectScreen ? <p className="result">{selected.length} {t("selected")}</p> : <p className="result">{props.totalCount} {t("results")}</p>}
                        {selectScreen ? <p className="select-all" onClick={() => selectAll()}>{t("Select all")}</p> : null}
                    </div>
                    <div className="right">
                        {selectScreen ?
                            <div className="cancel">
                                <p onClick={() => {setSelectScreen(false); setSelected([])}}>{t("Cancel")}</p>
                                <div  onClick={() =>setShowSelectedDetails(true)}>
                                    <Icon size={1} path={mdiDotsHorizontal}/>
                                </div>
                            </div>
                            : 
                            <p onClick={() =>setSelectScreen(true)}>{t("Select")}</p>
                        }
                    </div>
                </div>
            {props.visuals.length ?
            <div className="visuals">
                {props.visuals.filter(x => x.fileType === "video" || x.fileType === "image").map((media, index) => {
                        return(
                            <div key={index} className="visual">
                                {userData.favorites.media.includes(media._id) ? <div className="fav-star"><Icon size={1} className={""} path={mdiStar}/></div> : null}
                                { media.fileType === "image" ?
                                    <LazyLoadImage src={media.dataS ? media.dataS : media.data}
                                        placeholderSrc={media.dataXs ? media.dataXs : media.data}
                                        alt="Image Alt"
                                        effect="blur"
                                        onClick={() => imageclick(media)}
                                    />
                                :
                                <div className="video-visual">
                                    <video id={`vid-${media._id}`} controls={false} onClick={() => imageclick(media)} preload="metadata">
                                        <source src={`${media.data}#t=0.5`} type="video/mp4"/>
                                    </video>
                                    <p className="duration"><Icon size={1} path={mdiVideo}/>  <span id={`vid-dur-${media._id}`}>{getDuration(media)}</span></p>
                                </div>}
                                {selectScreen ? <div onClick={() => imageclick(media)}><Icon size={1.3} className={selected.map(x => x._id).includes(media._id) ? "select-active" : "select"} path={mdiCheckCircleOutline}/></div> : null}
                            </div>
                        )
                    })}
            </div>
            : <IFrameDisplay pageName={"my_profile-visuals"} />}
            {fullScreen && <ImageFullscreenViewer close={() => setFullScreen(false)} images={selected} />}
            {props.visuals.length && props.hasMore ? (
                <div ref={props.loadMoreRef} style={{ height: "1px", margin: "10px 0", marginTop: "0px", alignItems: "center", justifyContent: "center", display: "flex" }}>
                    {props.loading && <Spinner animation="border" variant="#fff" />}
                </div>
            ) : null}
            <button className="light-button" onClick={() => history.push({pathname: '/dashboard/stories/3' })}>{t("View all visuals")}</button>

            <Modal dialogClassName={"story-action-modal"} show={ showSelectedDetails} onHide={() => setShowSelectedDetails(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="edit-card-content">
                    <div className="link-row" onClick={() => {setFullScreen(true);  setShowSelectedDetails(false);}}>
                        <Icon size={1} path={ mdiArrowExpand } /><p className="main-text-single">{t("View Fullscreen")}</p>
                    </div>
                    {selected.length===1 && !userData.favorites.media.includes(selected[0]._id) ? 
                        <div className="link-row" onClick={addToFavorites}>
                            <Icon size={1} path={ mdiStar } /><p className="main-text-info">{t("Add to Favourite Media")}</p>
                            <p className="info-text">{t("For easy future access")}</p>
                        </div>
                    : selected.length===1 && userData.favorites.media.includes(selected[0]._id) ? 
                        <div className="link-row" onClick={removeFromFavorites}>
                            <Icon size={1} path={ mdiStarOff } /><p className="main-text-info">{t("Remove from Favourite Media")}</p>
                        </div>                        
                    : 
                    <div>
                        <div className="link-row" onClick={addToFavorites}>
                            <Icon size={1} path={ mdiStar } /><p className="main-text-info">{t("Add all to Favourite Media")}</p>
                            <p className="info-text">{t("For easy future access")}</p>
                        </div>
                        <div className="link-row" onClick={removeFromFavorites}>
                            <Icon size={1} path={ mdiStarOff } /><p className="main-text-info">{t("Remove all from Favourite Media")}</p>
                        </div>
                    </div>                                
                    }
                </Modal.Body>
            </Modal>
            {visualDetails && <VisualDetailsModal show={showVisualDetails} setShow={setShowVisualsDetails} visual={visualDetails} nextVisual={nextVisual} previousVisual={previousVisual}/>}
        </div>
    );
}