import React, { useState, useEffect } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import { blockTypes, IBlock, IStory } from "../../../../interfaces/story";
import instanceUpload from "../../../../api/upload";
import AudioRecorder from "../../../_helper/audio-recorder.component";
import Help from "../../../_helper/help.component";
import AudioUploader from "../../../_helper/audio-uploader.component";
import { INotification, NotificationType } from "../../../../interfaces/notification";
import Notification, { notificationImages } from "../../../notifications/notification.component";
import UploadLimitReachedModal from "../../upload-limit-reached.modal";
import axios, { AxiosError } from "axios"
import { useTranslation } from 'react-i18next';

interface IProps {
    addBlockToStory: Function
    data: IBlock | boolean
    closeModal: Function
    story: IStory
}

enum inputTypes {
    RECORDING="1",
    UPLOAD="2"
}

export default function AudioBlockInput(props:IProps) {

    //const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [inputType, setInputType ] = useState<inputTypes>(inputTypes.RECORDING);
    const [loading, setLoading] = useState<boolean>(false);
    const [ temp, setTemp ] = useState<string>("");    
    const [uploadPercentage, setUploadPercentage] = useState(0)

    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [editData, setEditData] = useState<string | boolean>(false);

    const [uploadLimitReachedModal, setUploadLimitReachedModal] = useState(false)

    //filesize notification
    const [ fileSizeErrorNotifican, setFileSizeErrorNotification ] = useState<boolean>(false);

    const { t } = useTranslation();

    const notificationData : INotification =  { 
        icon: notificationImages.crying, 
        title: t("Error"), 
        text: t("Failed to upload audio file"), 
        success: NotificationType.problem 
    };
    
    const errorFileSizeNotificationData : INotification = {
        icon: notificationImages.crying,
        title: t("Failed"),
        text: t("Audio must be under 50 MB"),
        success: NotificationType.problem
    };    

    useEffect(() => {        
        if (uploadPercentage === 100) {
            setTimeout(() => {
                setLoading(false)
            }, 2000);
        }
    }, [uploadPercentage])

    useEffect(() => {
        if(props.data) {
            if((props.data as IBlock).media.data) {
                console.log('setting editData')
                setEditData((props.data as IBlock).media.data)
                if((props.data as IBlock).media.description) {
                    setDescription(((props.data as IBlock).media.description) as string)
                }
            }
        }
    }, [])

    async function addToStory(audioFile:any) {
        setLoading(true);

        const fileType = audioFile.type && audioFile.type.match(new RegExp(/audio\/mp4.*/, "i")) ? "m4a" : "mp3";
        const fileName = "audio."+fileType;
        const audio = new File([audioFile], fileName, {type:"audio/"+fileType, lastModified:new Date().getTime()});

        //Show error if size > 100 MB
        if(audio.size > 100000000) {
            setFileSizeErrorNotification(true)
            return;
        }

        //Init formdata and cookies
        const formdata = new FormData();

        formdata.append("file", audio);
        formdata.append("story", props.story._id)
        formdata.append("description", description)
        formdata.append("type", blockTypes.AUDIO)
        formdata.append("isBlock", "true")
        
        try {
            const options = {
                // get the status of the file upload
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent
                    let percent = Math.floor((loaded * 100) / total)
                    setUploadPercentage(percent * 0.8) // the file is uploaded, waiting for the response
                },
            }

            const response = await instanceUpload.post('/upload', formdata, options);
            props.addBlockToStory({data:response.data.data, media:response.data._id ,type:blockTypes.AUDIO}); // TODO: LOW: to delete: obsolete: mediaTitle:title,
            setUploadPercentage(100);

            setLoading(false);
        }
        catch(error) {
            setLoading(false);
            const isAxiosError = axios.isAxiosError(error)
            if ( isAxiosError && (error as AxiosError).response?.data === "Upload Limit Reached" ) {
                setUploadLimitReachedModal(true)
            } else {
                setShowNotification(true)
                console.log("Error while uploading audio: ", error)
            }
        }
    }

    // TODO: medium: when the file is being loaded but not yet loaded, both AudioRecorder and AudioUploader should be disabled

    return(
        <div className="record-audio">
            <Tabs className="record-audio-tabs" activeKey={inputType} onSelect={(k:any) => setInputType((k as inputTypes))}>
                <Tab eventKey={inputTypes.RECORDING} title={t("Record")}>
                    <div className="inner">
                        {!editData ? <Form.Group className="group">
                            <input placeholder={t("Title")} value={description} onChange={(evt) => (description.length < 50 || evt.target.value.length < 50) ? setDescription(evt.target.value) : false} type="text"></input>
                            <label className="top-label">{t("Title")}</label>
                            <p className="sub-subtitle right">{description.length} /50</p>
                        </Form.Group> : 
                        <p className="audio-title">{description}</p>}
                        <AudioRecorder loading={loading} uploadPercentage={uploadPercentage} editData={editData} buttonName={'Re-record audio'} cancel={props.closeModal} seconds={1800} addToStory={addToStory} hasTitle={true} title={description} />
                    </div>
                </Tab>
                <Tab eventKey={inputTypes.UPLOAD} title={t("Upload")}>
                    <div className="inner">
                        {!editData ? <Form.Group className="group">                        
                            <input placeholder={t("Title")} value={description} onChange={(evt) => (description.length < 50 || evt.target.value.length < 50) ? setDescription(evt.target.value) : false} type="text"></input>
                            <label className="top-label">{t("Title")}</label>
                            <p className="sub-subtitle right">{description.length} /50</p>
                        </Form.Group> : 
                        <p className="audio-title">{description}</p>}
                        <AudioUploader loading={loading} uploadPercentage={uploadPercentage} buttonName={'Re-record audio'} cancel={props.closeModal} addToStory={addToStory} hasTitle={true} title={description} />
                    </div>
                </Tab>
            </Tabs>

            {showNotification ? <Notification data={notificationData} close={() => setShowNotification(false)} /> : null}
            {fileSizeErrorNotifican ? <Notification data={errorFileSizeNotificationData} close={() => { setEditData(false); setFileSizeErrorNotification(false) }}/> : null}

            <Help category={"PUBLISHING"} pageName={"Adding Audio Recording"}></Help>
            <UploadLimitReachedModal show={uploadLimitReachedModal} setUploadLimitReachedModal={setUploadLimitReachedModal} />
        </div>
    );
}
