import React, { useEffect, useState, useRef } from "react";
import useRecorder from "./useRecorder.component";
import WaveSurfer from 'wavesurfer.js';
import { mdiMicrophone, mdiPause, mdiPlay, mdiStop } from "@mdi/js";
import Icon from "@mdi/react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ProgressBar, Spinner } from 'react-bootstrap';
import { INotification, NotificationType } from "../../interfaces/notification"
import Notification, { notificationImages } from "../notifications/notification.component"
import { useTranslation } from "react-i18next";

interface IProps {
    editData:string | boolean
    addToStory:Function
    title:string
    hasTitle:boolean
    seconds:number
    cancel:Function
    buttonName:string
    uploadPercentage:number
    loading:boolean
}

export default function AudioRecorder(props:IProps) {

    const [audioURL, isRecording, audioFile, startRecording, stopRecording, setAudioURL] = useRecorder();

    // Countdowns for record, playback, and start
    const [seconds, setSeconds] = useState(props.seconds);
    const [countDown, setCountDown] = useState<boolean | number>(false);

    const [ audio, setAudio ] = useState<any>(false);
    const [ play, setPlay ] = useState<boolean>(false);
    const [ waveSurfer, setWaveSurfer ] = useState<any>(false);

    //Audio pemisions
    const [ hasPermission, setPermission ] = useState<boolean>(false);

    const { t } = useTranslation();

    const [showNotification, setShowNotification] = useState<boolean | INotification>({ icon: notificationImages.noimage, title: t("Caution"), text: t("If your device's screen turns off, the recording will stop. Tap the screen to keep it awake or disable the 'auto-lock / screensaver' feature in Settings > Display."), success: NotificationType.info })

    const waveSurferRef = useRef<any>();
    useEffect(() => {
        waveSurferRef.current = waveSurfer;
    }, [waveSurfer]);

    const timeToWait: number = 3;

    useEffect(() => {
        if(props.editData) {
            setAudioURL(props.editData);
        }
    }, [props.editData])

    useEffect(() => {
        checkPermissions();
    }, [])

    useEffect(() => {
        if(isRecording) {
            if (seconds > 0) {
                setTimeout(() => setSeconds(seconds - 1), 1000);
            } else {
                (stopRecording as Function)()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecording, seconds]);

    useEffect(() => {
        if(countDown) {
            if(countDown as number > 1) {
                setTimeout(() => setCountDown((countDown as number) - 1), 1000);
            }
            else {
                (startRecording as Function)();
                setCountDown(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countDown]);


    useEffect(() => {
        if(audioURL && !waveSurfer) {
            //setAudio(new Audio((audioURL as string)))
            const wavesurfer = WaveSurfer.create({
                backend: 'MediaElement',
                container: '#waveform',
                waveColor: 'grey',
                progressColor: 'green',
                barWidth: 3,
                barGap:2,
                height: 100,
                hideScrollbar: true,
                responsive: true,
                fillParent: true,
                autoCenter: true,
            });

            setWaveSurfer(wavesurfer);

            wavesurfer.load(new Audio((audioURL as string)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioURL])

    useEffect(() => {
        if(waveSurfer) {
            waveSurfer.backend.on('audioprocess', function (time:any) {
                let current = parseFloat(time.toFixed(1));
                let max = parseFloat(waveSurfer.backend.getDuration().toFixed(1))
                if ((current + 0.2) >= max) {
                    setPlay(false);
                }

                const totalTime = waveSurfer.getDuration();
                const  currentTime = waveSurfer.getCurrentTime();
                const remainingTime = totalTime - currentTime;
                
                let totalHours = Math.floor(totalTime / 3600);
                let totalMins = Math.floor(totalTime / 60) - (totalHours * 60);
                let totalSecs = Math.floor(totalTime % 60);

                let hours = Math.floor(remainingTime / 3600);
                let mins = Math.floor(remainingTime / 60) - (hours * 60);
                let secs = Math.floor(remainingTime % 60);

                const element = document.getElementById(`time-remaining`);

                if(waveSurfer.isPlaying() || mins || secs) {
                    // if playing or remained time is not zero-> show the remained time
                    if(element !== null) {
                        element.innerText =  mins.toString().padStart(2, '0') + ':' + secs.toString().padStart(2, '0');
                    }
                } else if (totalMins || totalSecs) {
                    // at the end of the play -> go back to the start
                    if(element !== null) {
                        waveSurfer.seekTo(0)
                        element.innerText =  totalMins.toString().padStart(2, '0') + ':' + totalSecs.toString().padStart(2, '0');
                    }
                }
                
            });
            waveSurfer.on('ready', function () {
                // possibly an error or the optimization not finished yet
                if(audioURL) setAudio(new Audio((audioURL as string)))                
            });               
        }          
    }, [waveSurfer])

    useEffect(() => {

        return () => {
            if(waveSurferRef.current) {
                waveSurferRef.current.pause();
                waveSurferRef.current.destroy();
            }
            setPlay(false);
            (stopRecording as Function)()
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function displayRecord() {
        if(!audio) {
            if(isRecording) {
                return(<button className="record-button stop" onClick={() => (stopRecording as Function)()}><Icon size={1.3} path={ mdiStop }/></button>)
            }
            else if(!audioURL) {
                return(<button className={"record-button record " + (countDown ? "hidden" : "")} onClick={() =>{ setCountDown(4) }}>{countDown ? displayCountdown() : <Icon size={1} path={ mdiMicrophone }/>}</button>)
            }
            else {
                return(<Spinner animation="border" variant="#fff" />)
            }
        }
        else {
            if(play) {
                return(<button className="record-button pause" onClick={() => { waveSurfer.pause(); setPlay(false)}}><Icon size={1.3} path={ mdiPause }/></button>)
            }
            else {
                return(<button className="record-button play" onClick={() => { waveSurfer.play(); setPlay(true) }}><Icon size={1.3} path={ mdiPlay }/></button>)
            }
        }
    }

    function reRecord() {
        setAudio(false);
        setAudioURL(false);
        setSeconds(props.seconds);
        setPlay(false);
        waveSurfer.destroy();
        setWaveSurfer(false);
    }

    function checkPermissions() {
        const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
        permissions.then((stream) => {
            setPermission(true);
        })
        .catch((err) => {
            setPermission(false);
        });
    }

    function displayRecordHelpText() {
        let infoText: string = "";
        if (countDown) {            
        } else if (audio) {
            infoText = t("Play record");
        } else if(play) {
            infoText = t("Playing");
        } else if(isRecording) {
            infoText = t("Recording...");
        }
        else {
            infoText = t("Click to record");
        }
        return infoText;
    }

    function displayCountdown() {
        return (
            <div className="timer-countdown-item">
                <div className="count-container">
                    {/* Progressbar gets its dimensions from these style properties, class does not work */}
                    <div style={{ width: "64px", height: "64px" }}>
                        <CircularProgressbar
                            strokeWidth={6}
                            value={(countDown as number) - 1}
                            maxValue={timeToWait}
                            text={"" + ((countDown as number) - 1) as string}
                            counterClockwise={true}
                            styles={{
                                root: {},
                                path: {
                                    stroke: `#fff`,
                                    strokeLinecap: "round",
                                    transition:
                                        "stroke-dashoffset 0.5s ease 0s",
                                    transform: "rotate(0turn)",
                                    transformOrigin: "center center",
                                },
                                trail: {
                                    stroke: "rgba(0, 0, 0, 0.4)",
                                    strokeLinecap: "butt",
                                    transform: "rotate(0.25turn)",
                                    transformOrigin: "center center",
                                },
                                text: {
                                    fill: "rgba(23, 36, 61, 0.5)",
                                    fontSize: "32px",
                                    fontWeight: 500,
                                    lineHeight: "36px",
                                },
                                background: {
                                    fill: "transparent",
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className="record-section">
            {displayRecord()}
            <p className={"record-help " + (countDown ? "timer-text" : "")}>{countDown ? null : displayRecordHelpText()}</p>
            {
                !(isRecording as boolean) &&
                    <p className={"record-info " + (countDown ? "hidden" : "")}>
                        {(audio)
                            ? <span id={`time-remaining`}>{Math.floor((props.seconds-seconds) / 60).toString().padStart(2, '0')}:{((props.seconds-seconds) % 60).toString().padStart(2, '0')}</span>
                            : "Max." + " " + props.seconds/60 + " " + t("minutes") }
                    </p>
            }
            {(!play && !audio && showNotification) && <Notification data={showNotification as INotification} close={() => setShowNotification(false)} />}
            <div id="waveform"></div>
            {isRecording && <p className="record-info">{Math.floor(seconds / 60).toString().padStart(2, '0')}:{(seconds % 60).toString().padStart(2, '0')} {t("remaining")}</p>}
            <button className="default-button add-button" disabled={(props.hasTitle ? !props.title.length : false) || !audioFile || props.loading} onClick={() => props.addToStory(audioFile)}>
            {props.loading ? <Spinner animation="border" variant="#fff" /> : t("Add to Story")}
            {props.loading ? <ProgressBar className="progressbar" now={props.uploadPercentage} /> : <></>}
            </button>
            {audio && <button className="light-button" onClick={() => reRecord()}>{props.buttonName}</button>}
            <button className="light-button" onClick={() => props.cancel()}>{t("Cancel")}</button>
        </div>
    )
}